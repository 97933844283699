import React from "react"
import { Page } from "../../components/elements"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { DefaultHeader } from "../../components/elements"
import { PageCenter } from "../../components/elements/wrappers"
import { VideoCard } from "../../components/elements"

export default function VideosPage() {
    const {
        allMarkdownRemark: { edges: videos },
    } = useStaticQuery(
        graphql`
            {
                allMarkdownRemark(
                    filter: { fileAbsolutePath: { regex: "//videos//" } }
                    sort: { fields: frontmatter___category, order: ASC }
                ) {
                    edges {
                        node {
                            ...VideoFields
                        }
                    }
                }
            }
        `,
    )

    const obj = {}

    videos.forEach(({ node: { frontmatter: fm } }) => {
        if (!obj[fm.category]) obj[fm.category] = []
        obj[fm.category].push(fm)
    })

    return (
        <Page
            title="Videos"
            description="All active research Projects are cost-shared studies
        designed collaboratively between sponsoring companies and
        EGI scientists, providing Sponsors with the unique
        opportunity to shape the project and gain independent
        insights, interpretations, and evaluations to
        company-specific questions. All active projects are open for
        sponsorship from their launch throughout the two-year period
        of confidentiality and include both Regional and Thematic
        studies."
        >
            <Helmet>
                <style type="text/css">
                    {`
                    .video-card img {
                        clip-path: inset(15% 2% 15% 2%);
                        margin: -22px 0;
                        padding: 5px;
                    }
                    
                    .video-card .col {
                        display: flex;
                    }
                    `}
                </style>
            </Helmet>
            <PageCenter bg="white">
                <DefaultHeader title="Videos" subtitle="" type="small" />
                {Object.keys(obj).map(e => (
                    <div key={e} className="my-5 pb-4">
                        <h4 className="pb-3">{e}</h4>
                        <Row>
                            {obj[e].map(vid => (
                                <Link
                                    to={"/videos/" + vid.slug}
                                    key={vid.title}
                                    className="m-3"
                                    style={{
                                        color: "inherit",
                                        textDecoration: "none",
                                    }}
                                >
                                    <VideoCard
                                        title={vid.title}
                                        url={vid.url}
                                    />
                                </Link>
                            ))}
                        </Row>
                    </div>
                ))}
                <DefaultHeader
                    title="EGI Current Project Overviews"
                    subtitle="Project overview videos for current EGI Research are in development and will be posted as they become available."
                    type="small"
                />
                <p className="p-3">
                    All active research Projects are cost-shared studies
                    designed collaboratively between sponsoring companies and
                    EGI scientists, providing Sponsors with the unique
                    opportunity to shape the project and gain independent
                    insights, interpretations, and evaluations to
                    company-specific questions. All active projects are open for
                    sponsorship from their launch throughout the two-year period
                    of confidentiality and include both Regional and Thematic
                    studies.
                </p>
            </PageCenter>
        </Page>
    )
}
